import * as React from "react";
import { useEffect, useRef } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import PhotoPreview from "./PhotoPreview";

interface Props {
  marker: MapMarker;
  focus: boolean;
  onChangeComment: (marker: MapMarker, comment: string) => void;
  onClickDeleteButton: (marker: MapMarker) => void;
  onChangePhotos: (marker: MapMarker, photos: Photo[]) => void;
}

const MarkerListItemEditor = (props: Props): JSX.Element => {
  const handleClick = () => {
    props.onClickDeleteButton(props.marker);
  };

  const handleComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onChangeComment(props.marker, e.target.value);
  };

  const handleSuccessDirectUpload = (signedIds: string[]) => {
    const photos = props.marker.photos.map((photo) => {
      if (!photo.signed_id) {
        photo.signed_id = signedIds.shift();
      }
      return photo;
    });
    props.onChangePhotos(props.marker, photos);
  };

  const handlePhotoUrl = (fileList: FileList) => {
    const photos = Array.from(fileList).map((file) => {
      return { signed_id: null, url: URL.createObjectURL(file) };
    });
    props.onChangePhotos(props.marker, props.marker.photos.concat(photos));
  };

  const handleClickDeleteButton = (deletedPhoto: Photo) => {
    const photos = props.marker.photos.filter((photo) => {
      return photo != deletedPhoto;
    });
    props.onChangePhotos(props.marker, photos);
  };

  const textarea = useRef<HTMLTextAreaElement>();

  useEffect(() => {
    if (props.focus) {
      const length = textarea.current.value.length;
      textarea.current.setSelectionRange(length, length); // キャレットの位置を入力済みテキストの末尾にする
      textarea.current.focus();
    }
  }, [props.focus]);

  return (
    <div>
      <div className="d-flex">
        <div className="d-flex flex-column" style={{ width: "300px" }}>
          <textarea
            className="form-control mb-2"
            value={props.marker.comment}
            onChange={handleComment}
            ref={textarea}
            placeholder="コメントを入力"
          />
          <div className="d-flex flex-wrap">
            {props.marker.photos.map((photo, index) => {
              return (
                <PhotoPreview
                  key={index}
                  photo={photo}
                  onClickDeleteButton={handleClickDeleteButton}
                />
              );
            })}
            <DirectUploadProvider
              onSuccess={handleSuccessDirectUpload}
              render={({ handleUpload, ready }) => (
                <label
                  className="btn border bg-white d-flex align-items-center"
                  style={{ width: "85px", height: "85px", fontSize: "14px" }}
                  onClick={(e) => e.stopPropagation()} // marker選択イベントをキャンセル
                >
                  画像追加
                  <input
                    className="d-none"
                    type="file"
                    accept="image/*"
                    multiple={true}
                    disabled={!ready}
                    onChange={(e) => {
                      handleUpload(e.currentTarget.files);
                      handlePhotoUrl(e.currentTarget.files);
                      e.currentTarget.value = ''; // 同じファイルを選択してもchangeイベントを検知する
                    }}
                  ></input>
                </label>
              )}
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-grow-0 flex-shrink-0 align-self-center">
            <button
              className="close ml-3"
              type="button"
              aria-label="delete"
              onClick={handleClick}
            >
              <i className="fa fa-minus-circle"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarkerListItemEditor;
