import * as React from 'react';
import { useState } from 'react';
import ReactDiffViewer from 'react-diff-viewer';

interface Props {
  author: string;
  createdAt: string;
  oldValue: string;
  newValue: string;
}

const UpdateHistory = (props: Props): JSX.Element => {
  const [openDiff, setOpenDiff] = useState(false);
  const handleClick = () => {
    setOpenDiff(!openDiff);
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div>
          {props.author}さんが更新しました。
          <span className="text-primary" role="button" onClick={handleClick} >
            差分を{openDiff ? '閉じる' :'表示'}
          </span>
        </div>
        <div className="small text-muted align-self-end">
          {props.createdAt}
        </div>
      </div>
      {openDiff &&
        <div className="py-2">
          <ReactDiffViewer oldValue={props.oldValue} newValue={props.newValue} splitView={false} />
        </div>
      }
    </div>
  );
};

export default UpdateHistory;
