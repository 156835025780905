import * as L from 'leaflet';
import markerIconImage from './../../images/map-marker-alt.svg';

const PinIcon = L.icon({
  iconUrl: markerIconImage,
  iconSize: [25, 33],
  iconAnchor: [12.5, 33],
  popupAnchor: [0, -20]
});

export default PinIcon;
