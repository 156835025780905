import * as React from 'react';
import { useState } from 'react';
import * as L from 'leaflet';
import { Map, TileLayer } from 'react-leaflet';
import styled from 'styled-components';
import AimLayer from './AimLayer';

interface Props {
  center?: Point;
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 320px;
  border: 1px solid #dddddd;
`;

const DEFAULT_CENTER = L.latLng(36.104611, 140.084556); // 国土地理院
const DEFAULT_ZOOM = 11;
const MAP_TILE_URL = "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png";
const ATTRIBUTION = "&copy; <a href=&quot;https://maps.gsi.go.jp/development/ichiran.html&quot;>国土地理院</a>";

type ViewPosition = {
  lat: number;
  lng: number;
  zoom: number;
}

const AreaMap = (props: Props): JSX.Element => {

  const defaultCenter = props.center ? L.latLng(props.center.coordinates[1], props.center.coordinates[0]) : DEFAULT_CENTER;
  const defaultPosition = {lat: defaultCenter.lat, lng: defaultCenter.lng, zoom: DEFAULT_ZOOM};

  const [viewPosition, setViewPosition] = useState<ViewPosition>(defaultPosition);

  const handleMoveEnd = (e: L.LeafletEvent) => {
    const { lat, lng } = e.target.getCenter();
    setViewPosition({lat: lat, lng: lng, zoom: e.target.getZoom()});
  };

  const handleClickReset = () => {
    setViewPosition(defaultPosition);
  };
  const mapCenter = L.latLng([viewPosition.lat, viewPosition.lng]);

  return (
    <>
      <p className="text-muted small">地図をドラッグして中心座標を赤い十字マークに合わせてください。</p>
      <Wrapper>
        <AimLayer />
        <Map center={mapCenter} zoom={viewPosition.zoom} style={{width:'100%', height:'100%'}}
          minZoom={2}
          onMoveEnd={handleMoveEnd}
        >
          <TileLayer
            url={MAP_TILE_URL}
            attribution={ATTRIBUTION}
          />
        </Map>
      </Wrapper>
      <div className="my-2">
        <div className="input-group input-group-sm">
          <div className="input-group-prepend">
            <span className="input-group-text">緯度と経度</span>
          </div>
          <input className="form-control" type="number" readOnly
            name="latitude" value={viewPosition?.lat} />
          <input className="form-control" type="number" readOnly
            name="longitude" value={viewPosition?.lng} />
        </div>
      </div>
      <div className="my-2">
        <span className="text-primary" role="button" onClick={handleClickReset}>元に戻す</span>
      </div>
    </>
  );
};

export default AreaMap;
