import * as L from 'leaflet';
import markerIconImage from './../../images/red-cross.svg';

const CrossIcon = L.icon({
  iconUrl: markerIconImage,
  iconSize: [32, 32],
  iconAnchor: [16, 16],
});

export default CrossIcon;
