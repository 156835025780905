import * as React from 'react';
import * as ReactDOM from 'react-dom';

import UpdateHistory from './UpdateHistory';

document.addEventListener('DOMContentLoaded', () => {
  const collection = document.getElementsByClassName('js-update-history');
  Array.prototype.forEach.call(collection, function (element: HTMLElement) {
    ReactDOM.render(
      <UpdateHistory
        author={element.dataset['author']} createdAt={element.dataset['created']}
        oldValue={element.dataset['code1']} newValue={element.dataset['code2']} />,
      element
    );
  });
}, false);
