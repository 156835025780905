import * as React from 'react';
import { useContext } from 'react';
import * as L from 'leaflet';
import { Map, Marker, TileLayer } from 'react-leaflet';
import pinIcon from '../shared/PinIcon';
import bluePinIcon from '../shared/BluePinIcon';
import crossIcon from '../shared/CrossIcon';
import ModeContext from './ModeContext';

interface Props {
  center?: Point;
  markers: MapMarker[];
  selectedMarker?: MapMarker;
  onSelectMarker?: (marker: MapMarker) => void;
  onClickMap?: (point: Point) => void;
}

const boundsOfMap = (props: Props): L.LatLngBounds => {
  const markersBounds = boundsOfMarkers(props.markers);

  if (! props.center && ! markersBounds) {
    return DEFAULT_CENTER.toBounds(50_000);
  }
  if (! props.center && markersBounds) {
    return markersBounds;
  }

  const center = L.latLng(props.center.coordinates[1], props.center.coordinates[0]);

  if (! markersBounds) {
    return center.toBounds(8_000);
  }

  return markersBounds.extend(center);
};

const boundsOfMarkers = (markers: MapMarker[]): L.LatLngBounds | null => {
  if (! (markers.length > 0)) {
    return null;
  }
  const points = markers.map(it => {
    if (! (it.pin.coordinates[0] && it.pin.coordinates[1])) {
      return null;
    }
    return L.latLng(it.pin.coordinates[1], it.pin.coordinates[0]);
  }).filter(Boolean);
  if (points.length === 0) {
    return null;
  }

  return L.latLngBounds(points);
};

const Markers = (props: Props): JSX.Element => {
  const markers = props.markers.map((it, index) => {
    if (! (it.pin.coordinates[0] && it.pin.coordinates[1])) {
      return null;
    }
    const icon = (props.selectedMarker == it) ? bluePinIcon : pinIcon;
    const handleClick = () => {
      props.onSelectMarker(it);
    };
    return (
      <Marker key={index}
        position={L.latLng(it.pin.coordinates[1], it.pin.coordinates[0])}
        icon={icon}
        onClick={handleClick}
      />
    );
  });
  return <>{markers}</>;
};

const DEFAULT_CENTER = L.latLng(36.104611, 140.084556); // 国土地理院
const MAP_TILE_URL = "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png";
const ATTRIBUTION = "&copy; <a href=&quot;https://maps.gsi.go.jp/development/ichiran.html&quot;>国土地理院</a>";

const DocumentMap = (props: Props): JSX.Element => {
  const mode = useContext(ModeContext);

  const areaCenter = props.center && L.latLng(props.center.coordinates[1], props.center.coordinates[0]);

  let center = null;

  if (props.selectedMarker) {
    center = L.latLng(props.selectedMarker.pin.coordinates[1], props.selectedMarker.pin.coordinates[0]);
  }

  const bounds = boundsOfMap(props);

  const handleClick = (e: L.LeafletMouseEvent) => {
    if (mode == 'editor') {
      props.onClickMap({type: 'Point', coordinates: [e.latlng.lng, e.latlng.lat]});
    }
  };

  return (
    <Map center={center} bounds={bounds} style={{width:'100%', height:'100%'}}
      minZoom={2}
      onclick={handleClick}
    >
      <TileLayer
        url={MAP_TILE_URL}
        attribution={ATTRIBUTION}
      />
      {areaCenter &&
        <Marker
          position={areaCenter}
          icon={crossIcon}
        ></Marker>
      }
      <Markers {...props}></Markers>
    </Map>
  );
};

export default DocumentMap;
