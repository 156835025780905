import * as React from 'react';
import styled from 'styled-components';
import aimIconImage from './../../images/red-cross.svg';

const Image = styled.img`
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
  width: 32px;
  height: 32px;
  z-index: 1000;
`;

const AimLayer = (): JSX.Element => <Image src={aimIconImage} />;

export default AimLayer;
