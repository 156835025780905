import * as moment from 'moment';
import * as React from 'react';

interface Props {
  summaries: Summary[]
  summaryId: string
  onChange: (e) => void
}

const PastSummarySelectBox = (props: Props): JSX.Element => {

  if (! props.summaries) return null;
  if (props.summaries.length == 0) return null;

  return (
    <>
      <label htmlFor="PastSummarySelectBox" className="font-weight-bold">
        前回日時
      </label>
      <select id="PastSummarySelectBox" className="custom-select"
        value={props.summaryId} onChange={props.onChange}>
        {props.summaries.map(it => {
          return(
            <option key={it.id} value={it.id}>{moment(it.summed_at).format('YYYY/MM/DD HH:mm')}</option>
          );
        })}
      </select>
    </>
  );
};

export default PastSummarySelectBox;
