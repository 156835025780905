// Run this file by adding <%= javascript_pack_tag 'react_application' %> to the head of your layout file,
// like app/views/layouts/application.html.erb.

require.context('../images', true, /\.(png|svg)$/);

// React entry points
import '../react_app/areas_map';
import '../react_app/documents_history';
import '../react_app/documents_map';
import '../react_app/search_map';
import '../react_app/summaries_form';
