import * as React from "react";

interface Props {
  photo: Photo;
  onClickDeleteButton: (photo: Photo) => void;
}

const PhotoPreview = (props: Props): JSX.Element => {
  const handleDeletePhoto = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation(); // marker選択イベントをキャンセル
    props.onClickDeleteButton(props.photo);
  };

  return (
    <div className="d-flex flex-column">
      <img
        src={props.photo.url}
        width="85px"
        height="85px"
        className="border mr-1"
      ></img>
      <u className="btn text-primary" onClick={handleDeletePhoto}>
        削除
      </u>
    </div>
  );
};

export default PhotoPreview;
