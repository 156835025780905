import * as React from 'react';
import * as ReactDOM from 'react-dom';

import SearchMapContainer from './SearchMapContainer';

document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('searchMap');
  if (element) {
    ReactDOM.render(
      <SearchMapContainer
        documents={JSON.parse(element.dataset['documents'])}
      />,
      element
    );
  }
}, false);
