import * as L from 'leaflet';
import { Marker } from 'react-leaflet';
import * as React from 'react';
import pinIcon from '../shared/PinIcon';
import bluePinIcon from '../shared/BluePinIcon';

interface Props {
  markers: MapMarker[];
  selectedMarker?: MapMarker;
  onSelectMarker: (marker: MapMarker) => void;
}

const Markers = (props: Props): JSX.Element => {
  const markers = props.markers.map((marker: MapMarker) => {
    const lat = marker.pin.coordinates[1];
    const lng = marker.pin.coordinates[0];
    if (!(lat && lng)) return null;
    const icon = (props.selectedMarker == marker) ? bluePinIcon : pinIcon;
    return (
      <Marker key={marker.id}
        position={L.latLng(lat, lng)}
        icon={icon}
        onClick={() => { props.onSelectMarker(marker); }}
      />
    );
  });
  return <>{markers}</>;
};

export default Markers;
