import * as React from 'react';
import { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import MarkerListItemEditor from './MarkerListItemEditor';
import MarkerListItemViewer from './MarkerListItemViewer';
import ModeContext from './ModeContext';

interface Props {
  markers: MapMarker[];
  onChangeMarkers: (markers: MapMarker[]) => void;
  selectedMarker?: MapMarker;
  onSelectMarker?: (marker: MapMarker) => void;
}

const Wrapper = styled.div`
  background-color: white;
  height: 100%;
  overflow-y: scroll;
`;

const MarkerList = (props: Props): JSX.Element => {
  const mode = useContext(ModeContext);

  const activeListItem = useRef<HTMLLIElement>(null);

  useEffect(
    () => {
      activeListItem.current?.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      });
    },
    [ props.selectedMarker ]
  );

  const handleClickDeleteButton = (deletedMarker: MapMarker) => {
    const newList = props.markers.filter(it => {
      return it != deletedMarker;
    });
    props.onChangeMarkers(newList);
  };

  const handleChangeComment = (marker: MapMarker, newComment: string) => {
    const newMarker: MapMarker = {...marker, comment: newComment};
    const newList = props.markers.map(it => {
      return (it == marker) ? newMarker : it;
    });
    props.onChangeMarkers(newList);
  };

  const handleChangePhotos = (marker: MapMarker, newPhotos: Photo[]) => {
    const newMarker: MapMarker = {...marker, photos: newPhotos};
    const newList = props.markers.map(it => {
      return (it == marker) ? newMarker : it;
    });
    props.onChangeMarkers(newList);
  };

  if (props.markers.length == 0) {
    return (
      <Wrapper>
        <div className="p-3 small text-muted">ピンはありません</div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <ul className="list-group list-group-flush border-bottom">
        {props.markers.map((it, index) => {
          const isActive = (props.selectedMarker == it);
          return (
            <li key={index}
              ref={isActive ? activeListItem : null}
              className={`list-group-item list-group-item-action ${isActive && 'active'}`}
              onClick={() => props.onSelectMarker(it)}>
              {mode == 'editor' &&
              <MarkerListItemEditor
                marker={it}
                focus={isActive}
                onChangeComment={handleChangeComment}
                onClickDeleteButton={handleClickDeleteButton}
                onChangePhotos={handleChangePhotos}
              />
              }
              {mode == "viewer" && <MarkerListItemViewer marker={it} />}
            </li>
          );
        })}
        {props.markers.length > 3 &&
          <li className="list-group-item disabled small">{props.markers.length}件のピン</li>
        }
      </ul>
    </Wrapper>
  );
};

export default MarkerList;
