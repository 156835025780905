import * as React from "react";

interface Props {
  marker: MapMarker;
}

const PhotoList = (props: Props): JSX.Element => {
  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-wrap mt-1">
        {props.marker.photos.map((photo, index) => {
          return (
            <a
              key={index}
              href={photo.url}
              data-lightbox={`photos-${props.marker.id}`}
            >
              <img
                src={photo.url}
                width="85px"
                height="85px"
                className="border mr-1"
              ></img>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default PhotoList;
