import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import MarkerList from './MarkerList';
import SearchMap from './SearchMap';

interface Props {
  documents: Document[];
}

const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;
const LeftSidePane = styled.div`
  width: 100%;
  height: calc(100vh - 382px - 1rem);
  overflow-y: scroll;
`;
const MainPane = styled.div`
  position: absolute;
  top: -254px;
  left: 320px;
  width: calc(100vw - 336px);
  height: calc(100vh - 45px - 1rem);
`;

const SearchMapContainer = (props: Props): JSX.Element => {
  const [selectedMarker, setSelectedMarker] = useState<MapMarker>(null);

  // flat mapping
  const markers: MapMarker[] = [].concat(...props.documents.map((document) => document.map_markers));

  return (
    <Wrapper>
      <LeftSidePane>
        <div className="list-group list-group-flush border-bottom">
          <MarkerList
            documents={props.documents}
            selectedMarker={selectedMarker}
            onSelectMarker={setSelectedMarker}
          />
        </div>
      </LeftSidePane>
      <MainPane>
        <SearchMap
          map_markers={markers}
          selectedMarker={selectedMarker}
          onSelectMarker={setSelectedMarker}
        />
      </MainPane>
    </Wrapper>
  );
};

export default SearchMapContainer;
