import * as React from 'react';
import * as ReactDOM from 'react-dom';

import AreaMap from './AreaMap';

document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('areaMap');
  if (element) {
    const center = JSON.parse(element.dataset['center'] || null);
    ReactDOM.render(
      <AreaMap
        center={center}
      />,
      element
    );
  }
}, false);
