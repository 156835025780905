import * as React from "react";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import * as moment from "moment";
import PhotoList from "./PhotoList";

interface Props {
  documents: Document[];
  selectedMarker: MapMarker;
  onSelectMarker: (marker: MapMarker) => void;
}

const Wrapper = styled.div`
  background-color: white;
`;

const LeftCol = styled.div`
  flex-basis: 85%;
`;

const RightCol = styled.a`
  display: flex;
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  flex-basis: 15%;
  justify-content: center;
  align-items: center;
  :hover {
    background-color: #f3f3f3;
  }
`;

const MarkerItem = styled.div`
  cursor: pointer;
`;

const updated_by = (document: Document) => {
  const name = document.updated_by?.name || '削除済みのアカウント';
  const updated_at = moment(document.updated_at).format("YYYY/MM/DD HH:mm");
  const isUpdated = moment(document.updated_at).isAfter(
    moment(document.created_at)
  );
  return `${updated_at} ${name} ${isUpdated ? "更新" : "作成"}`;
};

const MarkerList = (props: Props): JSX.Element => {
  const activeMarker = useRef(null);
  useEffect(() => {
    activeMarker.current?.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }, [props.selectedMarker]);

  const list = props.documents.map((it: Document) => {
    return (
      <div key={it.id} className="d-flex border">
        <LeftCol className="list-group list-group-flush p-0">
          <div className="list-group-item p-2">
            <strong>{it.title}</strong>
            <div className="small text-muted">
              {it.area.prefecture.name} {it.area.name}
            </div>
            <div className="small text-muted">{updated_by(it)}</div>
          </div>
          {it.map_markers.length == 0 && (
            <div className="list-group-item small text-muted p-2">
              ピンはありません
            </div>
          )}
          {it.map_markers.map((marker, index) => {
            const isActive = props.selectedMarker == marker;

            return (
              <MarkerItem
                key={index}
                className={`list-group-item list-group-item-action p-2 ${
                  isActive ? "active" : ""
                }`}
                ref={isActive ? activeMarker : null}
                onClick={(): void => {
                  props.onSelectMarker(marker);
                }}
              >
                {marker.comment}
                <PhotoList marker={marker} />
              </MarkerItem>
            );
          })}
        </LeftCol>
        <RightCol
          href={it.url}
          data-toggle="tooltip"
          title={`${it.title}の文書を閲覧する`}
        >
          <i className="fa fa-pen-nib fa-lg text-secondary"></i>
        </RightCol>
      </div>
    );
  });

  return <Wrapper>{list}</Wrapper>;
};

export default MarkerList;
