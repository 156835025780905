import * as React from 'react';
import { useEffect, useState } from 'react';
import MarkerList from './MarkerList';
import DocumentMap from './DocumentMap';
import axios from 'axios';
import styled from 'styled-components';
import ModeContext, { Mode } from './ModeContext';

interface Props {
  mode: Mode;
  url?: string;
  center?: Point;
}

const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 320px;
  border: 1px solid #dddddd;
`;
const LeftSidePane = styled.div`
  flex: 0 0 352px;
  width: 352px;
  height: 100%;
  border-right: 1px solid #dddddd;
`;
const MainPane = styled.div`
  width: 100%;
  height: 100%;
`;

const DocumentMapContainer = (props: Props): JSX.Element => {
  const [area, setArea] = useState<Area>();
  const [markers, setMarkers] = useState<MapMarker[]>([]);
  const [selectedMarker, setSelectedMarker] = useState<MapMarker>(null);

  useEffect(() => {
    const url = props.url; // 内部APIにアクセスする
    const params = {};
    const headers = {
      'Accept': 'application/json',
    };
    if (! url) {
      return;
    }
    const fetchData = async (): Promise<void> => {
      try {
        const result = await axios.get(url, {
          headers: headers,
          params: params,
          timeout: 10_000
        });
        setMarkers(result.data['map_markers']);
        setArea(result.data['area']);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [props.url]);

  const addNewMarker = (point: Point) => {
    const newMarker = {id: null, comment: '', pin: point, document: null, photos: []};
    setMarkers(markers.concat([newMarker]));
    setSelectedMarker(newMarker);
  };

  const handleClickMap = (point: Point) => {
    addNewMarker(point);
  };

  const handleChangeMarkers = (markers: MapMarker[]) => {
    setMarkers(markers);
  };

  return (
    <ModeContext.Provider value={props.mode}>
      <Wrapper>
        <LeftSidePane>
          <MarkerList
            markers={markers}
            selectedMarker={selectedMarker}
            onSelectMarker={setSelectedMarker}
            onChangeMarkers={handleChangeMarkers}
          />
        </LeftSidePane>
        <MainPane>
          <DocumentMap
            center={props.center || area?.center}
            selectedMarker={selectedMarker}
            onSelectMarker={setSelectedMarker}
            onClickMap={handleClickMap}
            markers={markers}
          />
        </MainPane>
        <input type="hidden" name="map_markers" value={JSON.stringify(markers)} />
      </Wrapper>
    </ModeContext.Provider>
  );
};

export default DocumentMapContainer;
