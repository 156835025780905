import * as React from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import { useState } from 'react';

interface Props {
  defaultBody?: string
  pastBody: string
  sequenceNumber: number;
  summaryItemId?: string;
  templateId: string;
  areaId: string;
  requireDiff: boolean
}

const SummaryItemForm = (props: Props): JSX.Element => {

  const [body, setBody] = useState(props.defaultBody);
  const handleChange = (event) => {
    setBody(event.target.value);
  };

  return (
    <div className="row mb-3">
      <div className="col-6">
        <div className="pl-4">
          {props.summaryItemId &&
            <input type="hidden" name={`summary[summary_items_attributes][${props.sequenceNumber}][id]`} value={props.summaryItemId}/>
          }
          <input type="hidden" name={`summary[summary_items_attributes][${props.sequenceNumber}][area_id]`} value={props.areaId}/>
          <input type="hidden" name={`summary[summary_items_attributes][${props.sequenceNumber}][template_id]`} value={props.templateId}/>
          <textarea name={`summary[summary_items_attributes][${props.sequenceNumber}][body]`}
            className="form-control"
            value={body} onChange={handleChange}
            rows={body.split("\n").length + 2}
          />
        </div>
      </div>
      <div className="col-6">
        {props.requireDiff &&
        <div className="border rounded bg-white h-100">
          <ReactDiffViewer oldValue={props.pastBody} newValue={body} splitView={false}
            hideLineNumbers={true}
            showDiffOnly={false}
          />
        </div>
        }
      </div>
    </div>

  );
};

export default SummaryItemForm;
