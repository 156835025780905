import * as React from 'react';
import * as ReactDOM from 'react-dom';

import SummaryForm from './SummaryForm';

document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('summaryForm');
  if (element) {
    ReactDOM.render(
      <SummaryForm
        summary={JSON.parse(element.dataset['summary'])}
        url={element.dataset['url']}
      />,
      element
    );
  }
}, false);
