import * as React from 'react';
import * as ReactDOM from 'react-dom';

import DocumentMapContainer from './DocumentMapContainer';

document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('documentMap');
  if (element) {
    const center = JSON.parse(element.dataset['center'] || null);
    ReactDOM.render(
      <DocumentMapContainer
        mode={element.dataset['mode'] == 'viewer' ? 'viewer' : 'editor'}
        center={center}
        url={element.dataset['url']} />,
      element
    );
  }
}, false);
