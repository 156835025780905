import * as React from 'react';
import * as L from 'leaflet';
import { Map, TileLayer } from 'react-leaflet';
import Markers from './Markers';

interface Props {
  map_markers: MapMarker[];
  selectedMarker: MapMarker;
  onSelectMarker: (marker: MapMarker) => void;
}

const MAP_TILE_URL = "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png";
const ATTRIBUTION = "&copy; <a href=&quot;https://maps.gsi.go.jp/development/ichiran.html&quot;>国土地理院</a>";

const PointToLatLng = (point: Point): L.LatLng | null => {
  if (!point) return null;
  const [lng, lat] = point.coordinates;
  return L.latLng(lat, lng);
};

const boundsOfMarkers = (markers: MapMarker[]): L.LatLngBounds | null => {
  if (!markers.length) return null;
  const latlngs = markers.map(m => PointToLatLng(m.pin)).filter(Boolean);
  if (!latlngs.length) return null;
  return L.latLngBounds(latlngs);
};

const SearchMap = (props: Props): JSX.Element => {
  const center = PointToLatLng(props.selectedMarker?.pin);
  const bounds = boundsOfMarkers(props.map_markers);

  if (!bounds) return null;
  return (
    <Map center={center}
      bounds={bounds}
      style={{width:'100%', height:'100%'}}
      minZoom={2}
    >
      <TileLayer
        url={MAP_TILE_URL}
        attribution={ATTRIBUTION}
      />
      <Markers
        markers={props.map_markers}
        selectedMarker={props.selectedMarker}
        onSelectMarker={props.onSelectMarker}
      ></Markers>
    </Map>
  );
};

export default SearchMap;
