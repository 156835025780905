import * as moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import SummaryItemForm from './SummaryItemForm';
import PastSummarySelectBox from './PastSummarySelectBox';
import "react-datetime/css/react-datetime.css";
import Datetime from 'react-datetime';

interface Props {
  url: string;
  summary: Summary
}

const SummaryForm = (props: Props): JSX.Element => {
  const [pastSummaries, setPastSummaries] = useState<Summary[]>([]);
  const [lastSummaryId, setLastSummaryId] = useState<string>(''); // 前回のサマリーID
  const [lastSummary, setLastSummary] = useState<Summary>();
  const [summaryItems, setSummaryItems] = useState([]);

  useEffect(() => {
    const url = props.url; // 内部APIにアクセスする
    const params = {};
    const headers = {
      'Accept': 'application/json',
    };
    if (! url) {
      return;
    }
    const fetchData = async (): Promise<void> => {
      try {
        const result = await axios.get(url, {
          headers: headers,
          params: params,
          timeout: 10_000
        });

        setSummaryItems(result.data['templates']);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [props.url]);

  // 比較表示のため同じイベントの過去のサマリーの一覧を取得する
  useEffect(() => {
    const url = `/api/summaries`;
    const params = { 'event_id': props.summary.event_id };
    const headers = {
      'Accept': 'application/json',
    };
    if (! url) {
      return;
    }
    const fetchData = async (): Promise<void> => {
      try {
        const result = await axios.get(url, {
          headers: headers,
          params: params,
          timeout: 10_000
        });
        const pastSummaries = result.data.filter(it => it['id'] != props.summary.id);
        setPastSummaries(pastSummaries);
        // 直前に該当するもののIDを取得
        const previousSummaries = result.data.filter(it => moment(it['summed_at']).isBefore(props.summary.summed_at));
        const lastSummary = previousSummaries[0];
        if (lastSummary) {
          setLastSummaryId(lastSummary['id']);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [props.summary]);

  // 比較表示のため指定されたIDのサマリーの詳細を取得する
  useEffect(() => {
    if (! lastSummaryId) {
      return;
    }
    const url = `/api/summaries/${lastSummaryId}`;
    const params = {};
    const headers = {
      'Accept': 'application/json',
    };
    if (! url) {
      return;
    }
    const fetchData = async (): Promise<void> => {
      try {
        const result = await axios.get(url, {
          headers: headers,
          params: params,
          timeout: 10_000
        });
        setLastSummary(result.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [lastSummaryId]);

  const handleChangePastSummary = (e) => {
    const id = e.target.value;
    setLastSummaryId(id);
  };

  let c = 0;

  // 前回との比較UIを表示するかどうか
  const requireDiffVew = (pastSummaries && pastSummaries.length > 0 && lastSummaryId != '');

  return (
    <>
      <div className="row my-3">
        <div className="col-3">
          <label className="font-weight-bold" htmlFor="summary_summed_at">
            今回日時
          </label>
          <Datetime
            inputProps={{
              name: "summary[summed_at]",
              id: "summary_summed_at",
              className: "form-control",
              required: true,
              autoComplete: 'off'
            }}
            locale="ja-JP"
            initialValue={moment(props.summary.summed_at)}
            dateFormat="YYYY/MM/DD"
            timeConstraints={{ minutes: { min: 0, max: 59, step: 1 }}}
          />
        </div>
        <div className="col-3" />
        <div className="col-3">
          {requireDiffVew &&
          <PastSummarySelectBox
            summaries={pastSummaries}
            summaryId={lastSummaryId}
            onChange={handleChangePastSummary} />
          }
        </div>
        <div className="col-3" />
      </div>
      {
        summaryItems.map((template, i) => {
          return (
            <dl key={i}>
              <dt>{template['title']}</dt>
              <dd>{
                template['prefectures'].map((prefecture, j) => {
                  return (
                    <dl key={j}>
                      <dt className="pl-2">{prefecture['name']}</dt>
                      <dd>{prefecture['areas'].map((area, k) => {
                        const pastSummaryItem = lastSummary?.summary_items.find(it => it.area_id == area['id'] && it.template_id == template['id']);
                        const item = props.summary.summary_items.find(it => it.area_id == area['id'] && it.template_id == template['id']);
                        const document = area['document'];
                        const defaultBody = item ? item.body : document['summary'];
                        return(
                          <dl key={k}>
                            <dt className="pl-4">{area['name']}</dt>
                            <dd>
                              <SummaryItemForm sequenceNumber={c++}
                                defaultBody={defaultBody}
                                summaryItemId={item?.id}
                                templateId={template['id']} areaId={area['id']}
                                pastBody={pastSummaryItem?.body || ''}
                                requireDiff={requireDiffVew}
                              />
                            </dd>
                          </dl>
                        );
                      })}
                      </dd>
                    </dl>
                  );
                })
              }</dd>
            </dl>
          );
        })
      }
    </>
  );
};

export default SummaryForm;
